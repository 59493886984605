import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from "moment/moment";
import Flatpickr from "react-flatpickr";

import Select from "../common/Select"
import AsyncSelect from "../common/AsyncSelect"

import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";

import { ROUTE_FILTERS, STATUS_FILTERS } from "../tables/SubmissionsTable"

export default class Reports extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      data: {

      }
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _isFormValid(){
    let { data } = this.state

    let error = null
    if(!data.form){
      error = "Please select a form"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _create(){
    let { data } = this.state
    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })
    Backend.createReport(data)
    .then(report => {
      this.setState({ loading: false })
      this.props.onCreated(report)
      Notify.success("You will shortly receive an email with the report attached")
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      show,
      data,
      loading
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
        className={'sub-modal'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row mb-5">
            <label className="form-label">Form</label>
            <AsyncSelect
              placeholder={"Please select a form"}
              endpoint={window.Api.Forms}
              orderBy={"name"}
              isClearable={false}
              className={'custom-async-select__container'}
              classNamePrefix={'custom-async-select'}
              value={data.form ? {
                  value: data.form.id,
                  label: data.form.name,
                  data: data.form
              } : null}
              getOptions={options => {
                  return options.map(option => ({
                      value: option.id,
                      label: option.name,
                      data: option
                  }))
              }}
              onSelected={form => {
                data.form = form
                this.setState({ data })
              }}
            />
          </div>

          <div className="form-group row mb-5">
            <label className="form-label">Route</label>
            <Select
              value={data.route}
              options={ROUTE_FILTERS.values}
              placeholder="Select A Route"
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              className={'custom-async-select__container'}
              classNamePrefix={'custom-async-select'}
              onSelected={option => {
                data.route = option.value
                this.setState({ data })
              }}
            />
          </div>

          <div className="form-group row mb-5">
            <label className="form-label">Status</label>
            <Select
              value={data.submission_status}
              options={STATUS_FILTERS.values}
              placeholder="Select A Declaration Status"
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              className={'custom-async-select__container'}
              classNamePrefix={'custom-async-select'}
              onSelected={option => {
                data.submission_status = option.value
                this.setState({ data })
              }}
            />
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="mb-5">
                <label className={"mb-2"}>Min Date</label>
                <Flatpickr
                  className="form-control"
                  placeholder="Min Date"
                  value={data.starts_at}
                  options={{
                    enableTime: false,
                    noCalendar: false,
                    dateFormat: "Y-m-d",
                    static: true
                  }}
                  onChange={dates => {
                    data.starts_at = moment(dates[0]).format('YYYY-MM-DD')
                    this.setState({ data })
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-5">
                <label className={"mb-2"}>Max Date</label>
                <Flatpickr
                  className="form-control"
                  placeholder="Max Date"
                  value={data.ends_at}
                  options={{
                    enableTime: false,
                    noCalendar: false,
                    dateFormat: "Y-m-d",
                    static: true
                  }}
                  onChange={dates => {
                    data.ends_at = moment(dates[0]).format('YYYY-MM-DD')
                    this.setState({ data })
                  }}
                />
              </div>
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

          <div className='buttons-grid gg-7'>
            <button
                type="button"
                className="btn btn-light me-3"
                onClick={() => this.props.onHide()}
            >
              Dismiss
            </button>

            <button
                type="button"
                className="btn btn-primary"
                onClick={() => this._create()}
                disabled={loading}
            >
              Create
            </button>
          </div>

        </Modal.Footer>

      </Modal>
    )
  }
}

Reports.defaultProps = {
  title: "Create Report",
  message: "This action cannot be undone"
}
