import React from 'react'

import Tooltip from '@material-ui/core/Tooltip';

import Template from "./Template";
import ArrivalNotification from "./ArrivalNotification";
import DepartureNotification from "./DepartureNotification";

import Submission from "../../modal/Submission";
import Attachments from "../../modal/Attachments";
import DeleteSubmission from "../../modal/DeleteSubmission";

import Notify from "../../../../utils/Notify";
import Backend from "../../../../utils/Backend";

export default class DeclarationActions extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      submission: props.submission
    }
  }

  _generate(type, submission){
    Backend.generate(type, submission)
    .then(() => {
      Notify.success("You will receive the file shortly via email")
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  _handleDelete(submission, reason){
    this.setState({ isDeletingDeclaration: true })
    Backend.deleteSubmission(submission.id, reason)
    .then(() => {
      let label = "Deleted"
      if(submission.status != "draft"){
        label = "Invalidated"
      }
      Notify.success(`Declaration ${label}`)
      this.setState({
        isDeletingDeclaration: false,
        showDeleteSubmissionModal: false,
      })
      this.props.refresh()
    })
    .catch(e => {
      Notify.error(e.message)
      this.setState({ isDeletingDeclaration: false })
    })
  }

  render(){
    let {
      submission,
      isDeletingDeclaration,
      showAttachmentsModal,
      showDeleteSubmissionModal,
      showSubmissionSummaryModal
    } = this.state

    let deletableStatuses = [
      "draft",
      "released",
      "accepted",
      "registered",
      "under_review",
      "insufficient_funds",
    ]

    let showInvalidate = deletableStatuses.indexOf(submission.status) > -1

    let deleteLabel = "Delete"
    if(submission.status != "draft"){
      deleteLabel = "Invalidate"
    }

    let amendable = submission.mrn && submission.form.type == "ais" && submission.amendment_status != "processing"
    let vatSummary = submission.status === "released" && submission.form.type == "ais"

    let canShowArrivalNotification = submission.mrn && submission.form.type == "cds"
    let canShowDepartureNotification = submission.mrn && submission.form.type == "cds"

    return (
      <>
        <div className="submission-actions">
          <div className='row'>
            <div className='col-6 sa-left'>
              { submission.mrn &&
                <>
                  <button
                    onClick={() => {
                      if(submission.clearance_slip){
                        window.open(submission.clearance_slip.url)
                      }else{
                        this._generate("clearance-slip", submission)
                      }
                    }}
                    className="btn btn-sm btn-outline-primary btn-submission-action"
                  >
                    Clearance Slip
                  </button>
                  <button
                    onClick={() => {
                      if(submission.receipt){
                        window.open(submission.receipt.url)
                      }else{
                        this._generate("receipt", submission)
                      }
                    }}
                    className="btn btn-sm btn-outline-primary btn-submission-action"
                  >
                    PDF
                  </button>
                </>
              }
              {
                vatSummary &&
                <button
                  className="btn btn-sm btn-outline-primary btn-submission-action"
                  onClick={(e) => this.setState({showSubmissionSummaryModal: true})}
                >
                  VAT Summary
                </button>
              }
              { !submission.template &&
                <button
                  className="btn btn-sm btn-outline-primary btn-submission-action"
                  onClick={() => this.setState({showAttachmentsModal: true})}
                >
                  Attachments
                </button>
              }
            </div>
            <div className='col-6 sa-right'>
              <Template
                type="button"
                submission={submission}
              />

              { canShowArrivalNotification &&
                <ArrivalNotification
                  submission={submission}
                  onArrived={() => {
                    this.statesTable.current?.refresh()
                  }}
                />
              }

              { canShowDepartureNotification &&
                <DepartureNotification
                  submission={submission}
                  onDeparted={() => {
                    this.statesTable.current?.refresh()
                  }}
                />
              }

              { amendable &&
                <button
                  className="btn btn-sm btn-outline-primary btn-submission-action"
                  onClick={() => this.props.goTo(submission)}
                >
                  Amend
                </button>
              }
              {
                showInvalidate &&
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    this.setState({
                      selectedSubmission: submission,
                      showDeleteSubmissionModal: true
                    })
                  }}
                >
                  { deleteLabel }
                </button>
              }
            </div>
          </div>
        </div>

        {
          showSubmissionSummaryModal &&
          <Submission
            show={showSubmissionSummaryModal}
            submission={submission}
            onHide={() => this.setState({showSubmissionSummaryModal: false})}
          />
        }

        {
          showAttachmentsModal &&
          <Attachments
            show={showAttachmentsModal}
            submission={submission}
            onHide={() => this.setState({showAttachmentsModal: false})}
          />
        }

        {
          showDeleteSubmissionModal &&
          <DeleteSubmission
            show={showDeleteSubmissionModal}
            loading={isDeletingDeclaration}
            submission={submission}
            onConfirm={reason => this._handleDelete(submission, reason)}
            onHide={() => this.setState({showDeleteSubmissionModal: false})}
          />
        }
      </>
    )
  }
}

DeclarationActions.defaultProps = {

}
