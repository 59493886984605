import React from "react"

import Row from "./Row";

import General from "../../../utils/General";
import Form from "../../../utils/Form";


export default class Section extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      section: props.section,
      index: props.index,
      level: props.level,
      group: props.group,
      editable: props.editable,
      submission: props.submission,
      subSectionsTitle: Form.getSubSectionsTitle(props.section),
      subSectionsCreatable: Form.getSubSectionsCreatable(props.section),
      subSectionCreatableTemplate: Form.getSubSectionCreatableTemplate(props.section)
    }

    this.rowRefs = {}
    this.subSectionRefs = {}
  }

  componentWillReceiveProps(props) {
    this.setState(props)
  }

  getErrors(){
    let errors = []
    Object.keys(this.rowRefs).map((key, index) => {
      let rowRef = this.rowRefs[index]
      errors = [
        ...errors,
        ...rowRef.getErrors()
      ]
    })
    Object.keys(this.subSectionRefs).map((key, index) => {
      let subSectionRef = this.subSectionRefs[index]
      errors = [
        ...errors,
        ...subSectionRef.getErrors()
      ]
    })
    return errors
  }

  getUnansweredRecommendedFields(){
    let { section } = this.state
    let unansweredRecommendedFields = []

    Object.keys(this.rowRefs).map((key, index) => {
      let rowRef = this.rowRefs[index]
      if(rowRef){
        unansweredRecommendedFields = [
          ...unansweredRecommendedFields,
          ...rowRef.getUnansweredRecommendedFields().map(field => {
            return {
              field,
              path: section.title + " -> " + field.title
            }
          })
        ]
      }
    })
    Object.keys(this.subSectionRefs).map((key, index) => {
      let subSectionRef = this.subSectionRefs[index]
      if(subSectionRef){
        unansweredRecommendedFields = [
          ...unansweredRecommendedFields,
          ...subSectionRef.getUnansweredRecommendedFields().map(o => {
            return {
              ...o,
              path: section.title + " -> " + o.path
            }
          })
        ]
      }
    })
    return unansweredRecommendedFields
  }

  _renderRows(){
    let {
      group,
      section,
      editable,
      submission,
      subSectionCreatableTemplate
    } = this.state

    return section.rows.map((row, rowIndex) => {
      return (
          <Row
              ref={ref => this.rowRefs[rowIndex] = ref}
              row={row}
              index={rowIndex}
              editable={editable}
              group={group}
              submission={submission}
              onUpdated={rowData => {
                let sectionData = General.clone(section)
                sectionData.rows[rowIndex] = rowData
                this.props.onUpdated(sectionData)
              }}
              onBlur={this.props.onBlur}
              onDuplicate={rowData => {
                let sectionData = General.clone(section)

                console.log("££££", sectionData)
                sectionData.rows[rowIndex] = rowData

                console.log("333333", sectionData)

                this.props.onDuplicate(sectionData)
              }}
          />
      )
    })
  }

  _renderSubSections(){
    let {
      index,
      section,
      level,
      editable,
      subSectionsTitle,
      subSectionsCreatable,
      subSectionCreatableTemplate
    } = this.state

    return (
      <>
        {
          section.sections.map((subSection, subSectionIndex) => {
            return (
                <Section
                    ref={ref => this.subSectionRefs[subSectionIndex] = ref}
                    key={subSectionIndex}
                    section={subSection}
                    index={subSectionIndex}
                    editable={editable}
                    group={subSectionsCreatable ? subSectionIndex : 0}
                    onUpdated={subSectionData => {
                      section.sections[subSectionIndex] = subSectionData
                      this.props.onUpdated(section)
                    }}
                    level={level + 1}
                    onDelete={() => {
                      section.sections.splice(subSectionIndex, 1)
                      this.props.onUpdated(section)
                    }}
                    onBlur={this.props.onBlur}
                    onDuplicate={section => {
                      let sectionData = General.clone(section)

                      console.log("4444", sectionData)
                      sectionData.sections.push(section)
                      this.props.onUpdated(sectionData)
                    }}
                />
            )
          })
        }
        {
          (subSectionsCreatable && editable) && (
            <div className="row mt-5">
              <div className="col-md-12 mb-10">
                <a className="btn btn-light-primary"
                   onClick={() => {
                     let sectionData = General.clone(section)
                     let group = sectionData.sections.length
                     let blankSubSection = Form.resetAnswers(subSectionCreatableTemplate, group)
                     section.sections.push(blankSubSection)
                     this.props.onUpdated(section)
                   }}
                >
                  <i className="la la-plus"></i>
                  Add {subSectionsTitle}
                </a>
              </div>
            </div>
          )
        }
      </>
    )
  }


  render() {
    let {
      index,
      section,
      level,
      editable,
      subSectionsTitle,
      subSectionsCreatable,
      subSectionCreatableTemplate
    } = this.state

    let order = index + 1
    let id = `${General.removeSpecialCharacters(section.title.replaceAll(" ", ""))}_${level}`
    let headerId = `${id}_header_${order}`
    let bodyId = `${id}_body_${order}`

    let forceExpanded = level > 0
    let showClassName = forceExpanded ? `show` : ""

    let title = section.title
    let deletable = false

    if(section.box_code){
      title += ` [${section.box_code}]`
    }
    if(section.repeatable){
      title += ` #${order}`
      deletable = editable
    }
    if(section.title == "Product"){
      let answer = section.rows[0].fields[0].answer
      if(answer?.shipment_item?.name){
        title = answer.shipment_item.name
      }
    }

    return (
      <div className={`accordion-item level-${level}`}>
        <h2 className="accordion-header" id={headerId}>
          {
              deletable &&
              <>
                <a
                    href="javascript:;"
                    data-repeater-delete=""
                    className="btn btn-sm btn-light-danger section-delete"
                    onClick={() => this.props.onDelete()}
                >
                  <i className="la la-trash-o fs-3"></i>Delete {section.title}
                </a>
              </>
          }
          <button
              className={`accordion-button fs-4 fw-semibold collapsed`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={forceExpanded ? null : `#${bodyId}`}
              aria-expanded={"false"}
              aria-controls={bodyId}
          >
            {title}
          </button>
        </h2>
        <div
            id={bodyId}
            className={`accordion-collapse collapse ${showClassName}`}
            aria-labelledby={headerId}
            data-bs-parent="#importParties"
        >
          <div className="accordion-body">
          { section.sub_sections_top ? (
             <>
               {this._renderSubSections()}
               {this._renderRows()}
             </>
           ) : (
             <>
               {this._renderRows()}
               <br/><br/>
               {this._renderSubSections()}
             </>
           )
          }
          </div>
        </div>
      </div>
    )
  }
}
