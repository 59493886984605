import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from "moment/moment";
import Flatpickr from "react-flatpickr";


import Edit from "../tables/cells/Edit";
import Delete from "../tables/cells/Delete";

import Select from "../common/Select";
import Separator from "../common/Separator";
import AsyncSelect from "../common/AsyncSelect"

import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";

const SUBTYPE_FILTERS = {
  isMulti: true,
  name: {
    api: 'form_subtypes',
    display: 'Type',
  },
  values: [
    {
      label: 'H1',
      value: 'H1',
    },
    {
      label: 'H2',
      value: 'H2',
    },
    {
      label: 'H3',
      value: 'H3',
    },
    {
      label: 'H4',
      value: 'H4',
    },
    {
      label: 'H5',
      value: 'H5',
    },
    {
      label: 'H6',
      value: 'H6',
    },
    {
      label: 'H7',
      value: 'H7',
    },
    {
      label: 'I1',
      value: 'I1',
    },
    {
      label: 'G3/G4',
      value: 'G4G3',
    },
    {
      label: 'B1',
      value: 'B1',
    },
    {
      label: 'B2',
      value: 'B2',
    },
    {
      label: 'B4',
      value: 'B4',
    },
    {
      label: 'C1 C&F',
      value: 'C1 C&F',
    },
    {
      label: 'C1 B&E',
      value: 'C1 B&E',
    },
  ],
}


export default class BulkEdit extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      searching: true,
      answers: [],
      formFields: [],
      submissionIds: []
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _search = General.debounce(() => {
    let { formSubType, additionalExternalIds } = this.state

    this.setState({ submissionIds: [], searching: true, error: null })
    Backend.getSumbissionIds(formSubType.value, additionalExternalIds)
    .then(submissionIds => {
      this.setState({ submissionIds, searching: false })
    })
    .catch(error => {
      this.setState({ error: error.message, searching: false })
    })
  }, 2000)

  _isFormValid(){
    let { formSubType, submissionIds, answers, reason} = this.state

    let error = null
    if(!reason){
      error = "Please select a reason"
    }
    else if(!formSubType){
      error = "Please select a form"
    }
    else if(submissionIds.length == 0){
      error = "No declarations match your filters, please review and adjust"
    }
    else if(answers.length == 0){
      error = "Please add one or more answers"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _loadFormFields(){
    let { formSubType } = this.state
    if(!formSubType){
      return
    }

    Backend.getFields(formSubType.value, "text,code,number,date,datetime", true)
    .then(fields => {
      this.setState({
        formFields: fields,
        answers: [{
          group: 0,
          _id: General.uuid(),
        }],
        isLoadingFields: false
      })
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  _renderInput(answer, i){
    let { answers } = this.state
    let formatType = answer.field?.format.type
    if(formatType == "datetime" || formatType == "date"){
      let dateFormat = formatType == "date" ? 'Y-m-d' : 'Y-m-d H:i'
      let momentDateFormat = formatType == "date" ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm:ss'
      return (
        <Flatpickr
            className="form-control"
            value={answer.value}
            options={{
              dateFormat,
              enableTime: true,
              noCalendar: false,
              time_24hr: true,
            }}
            style={{
              height: 47.8,
              border: "transparent",
              backgroundColor: "#f5f8fa",
            }}
            onChange={dates => {
              let datetime = null
                if(dates.length > 0){
                  datetime = moment(dates[0]).format(momentDateFormat)
                }
                answers[i].value = datetime
                this.setState({ answers })
            }}
        />
      )
    }
    return (
      <input
        type="text"
        style={{height: 47.8 }}
        className="form-control form-control-lg form-control-solid"
        placeholder="Please type a value"
        value={answer.value}
        onChange={e => {
          answers[i].value = e.target.value || null
          this.setState({ answers })
        }}
      />
    )
  }

  _renderContent(){
    let {
      answers,
      formFields
    } = this.state

    if(formFields.length == 0 ){
      return null
    }

    return (
        <>
          <Separator text="Answers"/>
          { answers.map((answer, i) => {
              return (
                <div key={answer._id} className="row">
                  <div className="col-11">
                    <div className="row">
                      <div className="col-5">
                        <Select
                          options={formFields}
                          placeholder={"Please select a field"}
                          getOptionLabel={option => {
                            let label = option.title
                            if(option.box_code){
                              label += ` [${option.box_code}]`
                            }
                            return label
                          }}
                          getOptionValue={option => option.id}
                          value={answer.field}
                          className={'filter-solid custom-async-select__container'}
                          classNamePrefix={'custom-async-select'}
                          onSelected={field => {
                            answers[i].field = field
                            this.setState({ answers })
                          }}
                        />
                      </div>

                      <div className="col-2">
                        <input
                          type="text"
                          style={{height: 47.8 }}
                          className="form-control form-control-lg form-control-solid text-center"
                          placeholder="="
                          value={"="}
                          disabled
                        />
                      </div>

                      <div className="col-5">
                        { this._renderInput(answer, i) }
                      </div>
                    </div>
                  </div>

                  <div className="col-1 text-end pt-3">
                    {answers.length > 1 &&
                      <Delete
                        onDeleteClicked={() => {
                          let newAnswers = General.clone(answers)
                          newAnswers.splice(i, 1)
                          this.setState({ answers: newAnswers })
                        }}
                      />
                    }
                  </div>
                </div>
              )
            })
          }

          <div className="row">
            <div className="col">
              <button
                className="btn btn-light-primary btn-sm"
                onClick={() => {
                  answers.push({
                    _id: General.uuid(),
                    order: 0,
                  })
                  this.setState({ answers })
                }}
              >
                Add Answer
              </button>
            </div>
          </div>
        </>
    )
  }

  _amend(){
    let { formSubType, reason, submissionIds, answers } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })
    return Backend.bulkAmend(formSubType.value, submissionIds, answers, reason)
    .then(() => {
      this.props.onSaved()
      this.setState({ loading: false })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  render() {
    let {
      show,
      data,
      error,
      reason,
      loading,
      answers,
      searching,
      formSubType,
      submissionIds,
      additionalExternalIds
    } = this.state

    let textAlertClassName = submissionIds.length == 0 ? "text-danger" : "text-primary"
    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
        className={'sub-modal'}
        dialogClassName={"modal-50"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row mb-5">
            <label className="form-label">Reason</label>
            <textarea
              type="text"
              className="form-control"
              placeholder="Reason"
              id="reason"
              value={reason}
              onChange={e => {
                reason = e.target.value || null
                this.setState({ reason })
              }}
            />
          </div>

          <div className="form-group row mb-5">
            <label className="form-label">Form</label>
            <Select
              isClearable={true}
              value={formSubType}
              options={SUBTYPE_FILTERS.values}
              placeholder={"Please select a form"}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              className={'filter-solid custom-async-select__container'}
              classNamePrefix={'custom-async-select'}
              onSelected={option => {
                this.setState({
                  fields: [],
                  answers: [],
                  formSubType: option,
                }, () => {
                  this._search()
                  this._loadFormFields()
                })
              }}
            />
          </div>

          { formSubType &&
            <div className="form-group row mb-5">
              <label className="form-label">Additional UCR</label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid"
                placeholder="Additional UCR"
                disabled={loading}
                onChange={e => {
                  let additionalExternalIds = e.target.value || null
                  this.setState({
                    additionalExternalIds
                  }, () => this._search())
                }}
              />
              {!searching &&
                <span className={textAlertClassName}>
                  {submissionIds.length} Declaration(s) match these filters
                </span>
              }
            </div>
          }

          { this._renderContent() }

        </Modal.Body>

        <Modal.Footer>

          <div className='buttons-grid gg-7'>
            <button
                type="button"
                className="btn btn-light me-3"
                onClick={() => this.props.onHide()}
            >
              Close
            </button>

            <button
                type="button"
                className="btn btn-primary"
                onClick={() => this._amend()}
                disabled={loading}
            >
              Apply
            </button>
          </div>

        </Modal.Footer>

      </Modal>
    )
  }
}

BulkEdit.defaultProps = {
  title: "Bulk Edit Declarations",
  message: "This action cannot be undone"
}
