import React from "react"

import MainSection from "./components/form/MainSection";

import AmendSubmission from "./components/modal/AmendSubmission";
import Confirmation from "./components/modal/SubmissionConfirmation";

import General from "../utils/General";
import Notify from "../utils/Notify";
import Backend from "../utils/Backend";
import AuthManager from "../utils/AuthManager";

const BLOCK_CHANGING_SECTION_WHEN_ERRORS = false

export default class DeclarationForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser,
      previousIndex: null,
      currentIndex: 0,
      answers: [],
      inModal: props.inModal,
      editable: props.inModal ? props.editable : window.location.pathname.split('/').pop() !== 'view'
    }

    this.mainSectionRefs = {}
  }

  componentDidMount() {
    let submissionId =  this.props.inModal ? this.props.submissionId : this.props.match.params.submissionId

    if(submissionId){
      Backend.getSubmission(submissionId).then(submission => {
        let form = submission.form
        submission.show_hidden_fields = submission.status == "rejected"
        this.setState({submission, form})
      }).catch(e => Notify.error(e.message))
    }
  }

  _autoSave = General.debounce(() => {
    let { submission } = this.state
    let amendment = null
    if(submission.mrn){
      amendment = {status: "draft"}
    }
    this._handleSubmission(null, false, amendment)
  }, 2000)

  // todo: review / fix check and auto save
  _handleNextButton(index){
    let { submission } = this.state
    if(!submission.template && BLOCK_CHANGING_SECTION_WHEN_ERRORS){
      let errors = this.mainSectionRefs[index].getErrors()
      if(errors.length > 0){
        let message = [<p>There are errors in this section, please review before proceeding</p>]
        errors.forEach(error => {
          message =[
              ...message,
              <p>{error.field.title}: {error.message}</p>
          ]
        })
        Notify.error(<p>{message}</p>, 10000)
        return
      }
      index = this.state.currentIndex + 1
    }
    if(this.state.editable){
      this._autoSave()
    }
    this.setState({currentIndex: index})
  }

  _getUnansweredRecommendedFields(){
    let { submission } = this.state

    let unansweredRecommendedFields = []

    submission.form.sections.forEach((section, index) => {
      let sectionRef = this.mainSectionRefs[index]
      sectionRef.getUnansweredRecommendedFields().forEach(field => {
        unansweredRecommendedFields.push(field)
      })
    });

    return unansweredRecommendedFields
  }

  _getSectionAnswers(section){
    let answers = []
    section.rows.forEach(row => {
      row.fields.forEach(field => {
        if(!field.answer || field.format.type === "label") {
          return
        }
        let answer = General.clone(field.answer)

        if(field.format.type === "party"){
          answer.party = answer.party?.id
        }else if(field.format.type === "guarantee"){
          answer.guarantee = answer.guarantee?.id
        }else if(field.format.type === "shipment_item"){
          answer.shipment_item = answer.shipment_item?.id
        }

        answer.field = field.id
        answers.push(answer)
      })
    })

    section.sections.forEach(subSection => {
      answers = [
          ...answers,
          ...this._getSectionAnswers(subSection)
      ]
    })

    return answers
  }

  _getAnswers(){
    let {
      form,
    } = this.state

    let answers = []

    form.sections.forEach(section => {
      answers = [
          ...answers,
          ...this._getSectionAnswers(section)
      ]
    })

    return answers
  }

  _getSectionTemplateProperties(section){
    let templateProperties = []
    section.rows.forEach(row => {
      row.fields.forEach(field => {
        if(!field.template_property) {
          return
        }
        templateProperties.push(field.template_property)
      })
    })

    section.sections.forEach(subSection => {
      templateProperties = [
          ...templateProperties,
          ...this._getSectionTemplateProperties(subSection)
      ]
    })

    return templateProperties
  }

  _getTemplateProperties(){
    let {
      form,
    } = this.state

    let templateProperties = []

    form.sections.forEach(section => {
      templateProperties = [
          ...templateProperties,
          ...this._getSectionTemplateProperties(section)
      ]
    })

    return templateProperties
  }

  _handleSubmission(status, showToast=true, amendment=null, bypassRestrictedCodes=false){
    let { submission } = this.state

    let additionalExternalIds = null
    if(submission.additional_external_ids?.length > 0){
      additionalExternalIds = submission.additional_external_ids
    }
    let data = {
      answers: this._getAnswers(),
      additional_external_ids: additionalExternalIds,
      bypass_restricted_code: bypassRestrictedCodes
    }

    if(submission.template){
      data.name = submission.name
      data.template_properties = this._getTemplateProperties()
    }
    if(status){
      data.status = status
    }
    if(amendment){
      data.amendment_status = amendment.status
      if(amendment.reason){
        data.amendment_reason = amendment.reason
      }
    }

    this.setState({isSubmitting: true})

    Backend.updateDeclaration(submission.id, data)
    .then(submission => {
      if(status === "completed"){
        if(!AuthManager.currentUser.company.onboarding_checklist.submission){
          AuthManager.currentUser.company.onboarding_checklist.submission = true
        }
      }

      this.setState({
        // submission,
        // form: submission.form,
        isSubmitting: false,
      })

      let message = "Declaration Saved"
      if(submission.status === "processing"){
        message = "Declaration Submitted"
      }else if(submission.template){
        message = "Template Saved"
      }

      if(showToast){
        Notify.success(message)
      }

      if(status === "complete" || amendment?.status == "complete"){
        if(this.props.onClose){
          this.props.onClose(submission)
        }else{
          this.props.history.push("/")
        }
      }
    })
    .catch(e => {
      this.setState({isSubmitting: false})
      if(showToast) {
        Notify.error(e.message)
      }
    })

  }

  _updateSection(section, index){
    let {
      form
    } = this.state

    form.sections[index] = section
    this.setState({ form })
  }

  _renderMainSections(){
    let {
      form,
      submission,
      currentIndex,
    } = this.state

    return form.sections.map((section, index) => {
      return (
        <MainSection
            ref={ref => this.mainSectionRefs[index] = ref}
            editable={this.state.editable}
            section={section}
            index={index}
            currentIndex={currentIndex}
            submission={submission}
            onUpdated={sectionData => {
              this._updateSection(sectionData, index)
            }}
            onBlur={() => this._autoSave()}
        />
      )
    })
  }

  _renderSteps(form){
    let {
      submission,
      currentIndex
    } = this.state

    return (
        <div className="d-flex flex-row-auto w-100 w-lg-300px">
          <div className="stepper-nav flex-cente">
            {
              form.sections.map((section, index) => {
                let className = "current"
                if(index > currentIndex){
                  className = "pending"
                }else if(index < currentIndex){
                  className = "completed"
                }
                return (
                  <>
                    <div
                      className={`stepper-item me-5 ${className}`}
                      data-kt-stepper-element="nav"
                      data-kt-stepper-action="step"
                      onClick={e => {
                        if(index < currentIndex) {
                          this.setState({currentIndex: index})
                          return
                        }
                        this._handleNextButton(index)
                      }}
                    >
                      <div className="stepper-wrapper d-flex align-items-center">
                        <div className="stepper-icon w-40px h-40px">
                          <i className="stepper-check fas fa-check"></i>
                          <span className="stepper-number">{index+1}</span>
                        </div>
                        <div className="stepper-label">
                          <h3 className="stepper-title">
                            {section.title}
                          </h3>
                        </div>
                      </div>
                      <div className="stepper-line h-40px"></div>
                    </div>
                  </>
                )
              })
            }

            <div className="form-check form-check-custom form-check-solid mt-5">
              <input
                className="form-check-input me-3"
                type="checkbox"
                checked={submission.show_hidden_fields}
                onChange={e => {
                  submission.show_hidden_fields = e.target.checked
                  this.setState({ submission })
                }}
              />
              Show Hidden Fields
            </div>
          </div>
        </div>
    )
  }

  render() {
    let {
      form,
      submission,
      isSubmitting,
      currentIndex,
      showAmendModal,
      showConfirmationModal,
      unansweredRecommendedFields
    } = this.state

    if(!submission) return null

    let title = (
      <span className="fs-4 text-gray-700 fw-bold pe-3 d-none d-md-block">
        {`${submission.form.name} Declaration`}
      </span>
    )

    if(submission.template){
      title = (
        <div className="row">
          <div className="col-8">
          <input
            value={submission.name}
            onChange={e => {
              submission.name = e.target.value || null
              this.setState({submission})
            }}
            onBlur={() => this._autoSave()}
            className="form-control form-control-solid"
          />
          </div>
          <div className="col align-self-center">
            <label>Template</label>
          </div>
        </div>
      )
    }

    let destination = submission.form.type == "cds" ? "HMRC" : "Revenue"
    let submissionAlert = [<p>This declaration will be sent to {destination}.</p>]
    if(submission.source && submission.source_type == "template"){
      submissionAlert.push(
        <p>Please confirm if all information for the new shipment has been updated, as this declaration has been duplicated.</p>
      )
    }
    return (
      <>
        {submission.latest_state?.reason == "Declaration Rejected" &&
          <div className={"bg bg-light-danger p-8 mb-4"}>
            <div style={{whiteSpace: "pre-wrap"}}>{submission.latest_state.message}</div>

          </div>
        }
        <div className="toolbar" id="kt_toolbar">
          <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack"
               bis_skin_checked="1">
            <div className="d-flex align-items-center me-5 py-4" bis_skin_checked="1">
              <div className="d-flex align-items-center flex-shrink-0" bis_skin_checked="1">
                {title}
                <div className="d-flex flex-shrink-0" bis_skin_checked="1">
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center" bis_skin_checked="1">
              {
                this.state.inModal &&
                <button
                  className="btn btn-outline-dark btn-sm"
                  onClick={() => {
                      this.props.onClose(submission)
                  }}
                  disabled={isSubmitting}
                >
                  Close
                </button>
              }
              {
                submission.template ?
                  <>
                    <a
                      href="javascript:;"
                      className="btn btn-primary btn-sm"
                      onClick={() => this._handleSubmission()}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Updating..." : "Update"}
                    </a>
                  </>
                :
                  this.state.editable &&
                    <>
                      <button
                        className="btn btn-secondary btn-sm ms-5"
                        onClick={() => {
                          if (submission.mrn) {
                            this._handleSubmission(
                              submission.status,
                              false,
                              {status: "draft"}
                            )
                          } else {
                            this._handleSubmission("draft")
                          }
                        }}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Saving..." : "Save"}
                      </button>
                      <button
                        className="btn btn-primary btn-sm ms-5" id="submitDeclaration"
                        onClick={() => {
                          if (submission.mrn) {
                            this.setState({showAmendModal: true})
                          } else {
                            this.setState({
                              showConfirmationModal: true,
                              unansweredRecommendedFields: this._getUnansweredRecommendedFields()
                            })
                          }
                        }}
                        disabled={isSubmitting}
                      >
                        {submission.mrn ? "Amend" : "Submit"}
                      </button>
                    </>
              }
            </div>
          </div>
        </div>
        <div className="card mt-30 mb-xl-8">
          <div className="card-body py-10">
            <div className="stepper stepper-pills stepper-column d-flex flex-column flex-lg-row first">
              {this._renderSteps(form)}
              <div className="flex-row-fluid">
                <form className="form mx-auto">
                  <div className="mb-5">
                    {this._renderMainSections()}
                  </div>
                  <div className="d-flex flex-stack">
                    <div className="me-2">
                      {
                        currentIndex > 0 &&
                        <button
                          type="button"
                          className="btn btn-light btn-active-light-primary"
                          onClick={() => this.setState({currentIndex: currentIndex-1})}
                        >
                          Back
                        </button>
                      }
                    </div>

                    <div>
                      {
                        currentIndex < form.sections.length - 1 &&
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => this._handleNextButton(currentIndex)}
                        >
                          Continue
                        </button>
                      }
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {
          showConfirmationModal &&
          <Confirmation
            show={showConfirmationModal}
            title={"Are you sure?"}
            message={submissionAlert}
            disabled={isSubmitting}
            unansweredRecommendedFields={unansweredRecommendedFields}
            onSend={() => this._handleSubmission("complete")}
            onForceSend={() => this._handleSubmission("complete", true, null, true)}
            onHide={() => this.setState({showConfirmationModal: false})}
          />
        }
        {
          showAmendModal &&
          <AmendSubmission
            show={showAmendModal}
            title={"Are you sure?"}
            message={"The amendment will be sent to Revenue."}
            loading={isSubmitting}
            onConfirm={(reason) => {
              this._handleSubmission(
                submission.status,
                true,
                { status: "complete", reason }
              )
            }}
            onHide={() => this.setState({showAmendModal: false})}
          />
        }
      </>
    )
  }
}
