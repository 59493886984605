import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ReactTableDefaults } from 'react-table';
import { toast } from 'react-toastify';

import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

import logo from './logo.svg';

import Api from './constants/Api'
import General from './constants/General'

import './App.css';
import './assets/plugins/global/plugins.bundle.css';
import './assets/css/style.bundle.css';
import './assets/css/custom.css';
import './assets/scss/app.scss'
import './assets/plugins/icons/plugins.bundle.css'

import Pagination from "./pages/components/tables/Pagination";

import AuthApp from './pages/App'
import Register from "./pages/Register";
import Login from "./pages/Login";

Object.assign(ReactTableDefaults, {
  minRows: 5,
  PaginationComponent: Pagination
})

toast.configure()

export default class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  componentDidMount() {
    let scripts = []
    scripts.push(
      this._getScript(process.env.PUBLIC_URL + '/assets/plugins/global/plugins.bundle.js')
    )
    scripts.push(
      this._getScript(process.env.PUBLIC_URL + '/assets/js/scripts.bundle.js')
    )
    scripts.push(
      this._getScript(process.env.PUBLIC_URL + '/assets/js/custom.js')
    )

    setTimeout(() => {
      this._addScripts(scripts)
    }, 3000)
  }

  _getScript(path) {
    const script = document.createElement('script')

    script.src = path

    return script
  }

  // TO:DO remove need for sleep, the issue is vendors.bundle.js, takes too long to load
  // and scripts.bundle.js executes before it finishes causing all sorts of errors.
  async _addScripts(scripts) {
    for (var i = 0; i < scripts.length; i++) {
      let script = scripts[i]
      document.body.appendChild(script)
      await this._sleep(500)
    }
  }

  _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  render() {
    let {
      loading
    } = this.state

    if(loading){
      return null
    }

    return (
      <>
        <BrowserRouter>
          <Switch>
            <UnauthenticatedRoute exact path="/login" component={Login} />
            {/*<UnauthenticatedRoute exact path="/forgot-password" component={ForgotPassword} />*/}
            {/*<UnauthenticatedRoute path="/reset-password/:email" component={ResetPassword} />*/}

            <Route path="/register" component={Register} />

            <AuthenticatedRoute path="/" component={AuthApp} />

            {/*<Route exact path="/404" component={NotFound} />*/}
            <Redirect to="/404" />
          </Switch>
        </BrowserRouter>
      </>
    )
  }

}

