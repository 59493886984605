import React from "react";
import Modal from 'react-bootstrap/Modal'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";

export default class TemplateProperty extends React.Component {

  constructor(props) {
    super(props);

    let data = {
      hidden: false,
      editable: true
    }

    if(props.template_property){
      if(props.template_property.id){
        data.id = props.template_property.id
      }
      data.hidden = props.template_property.hidden
      data.editable = props.template_property.editable
    }

    this.state = {
      data,
      show: props.show,
      title: props.title,
    }
  }

  render() {
    let {
      show,
      data,
      title,
      loading,
    } = this.state


    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit {title} Template Settings</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div
              className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll"
              data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px"
              style={{maxHeight: 458}} bis_skin_checked="1"
            >
              <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                <label className="required fw-semibold fs-6 mb-2">Hidden</label>
                <select
                    className="form-select"
                    aria-label="Container"
                    onChange={e => {
                      data.hidden = e.target.value == "true"
                    }}
                >
                  <option value="true" selected={data.hidden == true}>True</option>
                  <option value="false" selected={data.hidden == false}>False</option>
                </select>
              </div>

              <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                <label className="required fw-semibold fs-6 mb-2">Editable</label>
                <select
                    className="form-select"
                    aria-label="Container"
                    onChange={e => {
                      data.editable = e.target.value == "true"
                    }}
                >
                  <option value="true" selected={data.editable == true}>True</option>
                  <option value="false" selected={data.editable == false}>False</option>
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-right">
              <button
                className="btn btn-light ml-auto"
                onClick={() => this.props.onHide()}
              >
                Close
              </button>
              <button
                className="btn btn-primary ms-3"
                disabled={loading}
                onClick={e => {
                  this.props.onUpdated(data)
                }}
              >
                Save
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

}
